@import "variables"


.start-finish-container
  display: flex
  align-items: center
  .start-finish-save-confirmation
    color: $color-7
    display: none
    font-size: 1.5rem
    margin-left: 0.3em
    transition: display 0.5s
  .start-finish-contents
    background: rgba(199,193,171,0.3)
    border-radius: 5px
    padding: 0.5em
    width: max-content
    display: flex
    align-items: center
    justify-content: flex-start
    //height: max-content
    //width: max-content
    white-space: nowrap
    .start-finish-datepicker
      z-index: 10
      margin: 0 0.25em
    .start-finish-toggle-container
      .btn-start-finish-toggle
        border: none
        border-bottom: 1px dotted gray
        border-radius: 0
        margin: 0
        padding: 0 .5px
        height: min-content
        width: max-content
        text-align: left
        display: inline
        background: inherit
      .btn-start-finish-toggle:hover,
      .btn-start-finish-toggle:focus
        border: none
        border-bottom: 1px solid gray
    .btn-save
      padding: 0
      height: 2em
      width: 5em

    // Tooltip contents
    .start-finish-toggle-container::before
      display: none
      position: absolute
      content: attr(title)
      z-index: 2
      background: $color-tooltip-background
      color: #ffffff
      font-size: 0.8em
      border-radius: 5px
      width: max-content
      padding: 2px 6px
      white-space: nowrap
      transform: translateY(130%)
    .start-finish-toggle-container:hover::before,
    .start-finish-toggle-container:hover::after
      display: inline-block

    // Arrow for the tooltip
    .start-finish-toggle-container::after
      display: none
      position: absolute
      content: ""
      z-index: 1
      width: 0
      height: 0
      border: 5px solid transparent
      border-bottom-color: $color-tooltip-background
      transform: translateY(200%) translateX(-400%)

// fix the missing border-radius values
.react-datepicker__header
  border-top-left-radius: 0.3rem
  border-top-right-radius: 0.3rem
.react-datepicker__header--time
  border-top-left-radius: 0.3rem !important
.react-datepicker__today-button
  border-bottom-left-radius: 0.3rem
  border-bottom-right-radius: 0.3rem

// better aligns the Time popup with the Calendar popup
.react-datepicker__time-container--with-today-button
  right: -6.68em !important
  top: 1.78em !important

.react-datepicker__time
  height: 190px !important
  overflow: hidden
  border-bottom-left-radius: 0.3rem !important

// override the text cursor for headers on both popups
.react-datepicker-time__header,
.react-datepicker__current-month
  cursor: default

// the Today button needs hover background color
.react-datepicker__today-button:hover
  background-color: #e8e8e8

@media (max-width: 399px)
  .start-finish-container
    .start-finish-contents
      transition: all 0.4s
      flex-direction: column
      align-items: flex-start
      justify-content: center
      width: 100%
      .start-finish-datepicker
        margin: 0.25em 0
