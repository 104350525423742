@import "variables"


.header-container
  background: $color-nav-background
  color: $color-text-light
  margin: 0
  padding: 0.25em 1em
  font-size: 1.5rem
  .nav-content
    text-align: center
    .nav-list
      list-style: none
      margin: 0
      padding: 0
    .nav-link
      display: inline
      color: $color-text-light
      text-decoration: none
    .nav-link:hover,
    .nav-link:focus
      color: #ffffff
