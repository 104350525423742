@import variables
@import html-tags
@import input-controls
@import buttons
@import nav


/*** Page-Level ***/

.app-container
  background: $color-background
  margin: 0 auto
  max-width: 1200px
  text-align: center

.header-container,
.content-container,
.footer-container
  max-width: 1200px
  margin: 0 auto

.content-container
  margin: 0.65em

.sr-only
  position: absolute
  width: 1px
  height: 1px
  padding: 0
  margin: -1px
  overflow: hidden
  clip: rect(0,0,0,0)
  border: 0


/*** Other ***/

.loading-icon-title
  height: 2em

.page-title
  padding-bottom: 0.15em

.page-title-border
  border-bottom: 1px solid gray

.difficulty-icon
  height: 1.3em
  position: relative
  margin: 0 0.333em

.difficulty-icon .difficulty-tooltip-text
  width: auto
  background-color: $color-tooltip-background
  color: $color-tooltip-text
  text-align: center
  padding: 0.375em 0
  border-radius: 0.375em
  position: absolute
  z-index: 1

.difficulty-icon:hover .difficulty-tooltip-text
  display: block

.icon-delete
  background-color: $color-background
  height: 100%
  cursor: pointer

.tooltip
  visibility: hidden
  background: $color-tooltip-background
  color: $color-tooltip-text
  outline-color: #da532c

.icon-ext-link
  display: inline
  max-height: 0.55em
  max-width: 0.55em
  margin-left: 0.25em
  filter: brightness(0) saturate(100%) invert(54%) sepia(7%) saturate(0%) hue-rotate(226deg) brightness(92%) contrast(83%)

.icon-transparent
  background: $color-background

.show-hidden
  display: inline
  visibility: visible

.hidden
  display: none !important

/*** Active Elements ***/
//.collapse:not(.show)
//  display: none
//.collapsing
//  position: relative
//  overflow: hidden
//  height: 0
//  transition: height 0.35s ease

.footer-container
  font-size: 0.8rem
  margin: 1em
  cursor: default
  display: flex
  opacity: 0.75
  .footer-link
    margin-left: 0.35em
  .icon-bread
    margin-left: 0.2em
    height: 1em

@media (max-width: 494px)
  .footer-container
    justify-content: center
    transition: all 0.35s

@media (prefers-reduced-motion: no-preference)
  .icon-bread
    animation: spinning-bread infinite 20s linear
  .start-finish-save-confirmation
    animation: 1 ease-in 2s linear

@keyframes spinning-bread
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
